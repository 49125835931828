import ApplicationScreenWrapper from './components/ApplicationScreenWrapper'
import ApplicationTextInput from './components/ApplicationTextInput'
import { useFormContext } from './utils/FormContext'

const DriverApplicationPage = () => {
  const { form, goBack, handleNext, updateLoading, application } =
    useFormContext()

  return (
    <ApplicationScreenWrapper
      title={'What is your legal name?'}
      description={'Please confirm the following information.'}
      goBack={goBack}
      application={application}
      hideBackButton={true}
      updateLoading={updateLoading}
      saveButtontext={
        form.isDirty('firstName') ||
        form.isDirty('middleName') ||
        form.isDirty('lastName')
          ? 'Save'
          : 'Continue'
      }
      handleSubmit={() => {
        handleNext({
          firstName: form.values.firstName,
          middleName: form.values.middleName,
          lastName: form.values.lastName,
        })
      }}
    >
      <ApplicationTextInput
        placeholder="First Name"
        required
        label="First Name"
        {...form.getInputProps('firstName')}
      />
      <ApplicationTextInput
        label="Middle Name"
        placeholder="Middle Name"
        {...form.getInputProps('middleName')}
      />
      <ApplicationTextInput
        label="Last Name"
        required
        placeholder="Last Name"
        {...form.getInputProps('lastName')}
      />
    </ApplicationScreenWrapper>
  )
}

export default DriverApplicationPage
